import React, { useEffect, useState } from "react"

// Libraries
import { Link } from "gatsby"

// Icons
import IconLogo from "assets/icons/icon-logo.inline.svg"
import IconTelegram from "assets/icons/icon-telegram.inline.svg"
import IconTwitter from "assets/icons/icon-twitter.inline.svg"
import IconYouTube from "assets/icons/icon-youtube.inline.svg"
import IconDiscord from "assets/icons/icon-discord.inline.svg"
import IconWhatsApp from "assets/icons/icon-whatsapp.inline.svg"
import IconFacebook from "assets/icons/icon-facebook.inline.svg"
import IconLinkedIn from "assets/icons/icon-linkedin.inline.svg"

const Menu = () => {
  // State
  const [active, setActive] = useState(false)

  // Social links
  const socialLinks = [
    {
      name: "Telegram",
      icon: <IconTelegram />,
      url: "https://t.me/ndbtechnology",
    },
    {
      name: "Twitter",
      icon: <IconTwitter />,
      url: "https://twitter.com/ndbtechnology",
    },
    {
      name: "YouTube",
      icon: <IconYouTube />,
      url: "https://www.youtube.com/c/NDBplus",
    },
    {
      name: "Discord",
      icon: <IconDiscord />,
      url: "https://discord.gg/ubB93xKe",
    },
    {
      name: "WhatsApp",
      icon: <IconWhatsApp />,
      url: "https://wa.me/0014152373257",
    },
    {
      name: "Facebook",
      icon: <IconFacebook />,
      url: "https://www.facebook.com/ndbtechnology",
    },
    {
      name: "LinkedIn",
      icon: <IconLinkedIn />,
      url: "https://www.linkedin.com/company/ndbtechnology",
    },
  ]

  // Navigation Links
  const navigationLinks = [
    {
      label: "Home",
      url: "/",
    },
    {
      label: "Vision",
      url: "https://ndb.city",
    },
    {
      label: "Technology",
      url: "/technology",
    },
    {
      label: "Learn",
      url: "/learn",
    },
    {
      label: "Auction",
      url: "/auction",
    },
    {
      label: "Contact Us",
      url: "/#contactUs",
    },
  ]

  /**
   * Handles 'ESC' key pressing.
   */
  useEffect(() => {
    const handleEscKeyPress = event => {
      if (event.key === "Escape" && active) {
        setActive(false)
      }
    }

    document.addEventListener("keydown", handleEscKeyPress)

    return () => document.removeEventListener("keydown", handleEscKeyPress)
  })

  return (
    <nav className={active ? "menu menu--active" : "menu"}>
      <div className="container d-flex align-items-center justify-content-between">
        <Link to="/" className="menu__logo d-flex" title="Logo">
          <IconLogo />
        </Link>

        <div className="d-flex align-items-center">
          <ul className="menu__social-links d-none d-md-flex align-items-center me-4">
            {socialLinks.map(link => (
              <li key={link.name} className="me-3">
                <a
                  href={link.url}
                  className="svg-hover--stroke-green"
                  target="_blank"
                  rel="noopener noreferrer"
                  title={link.name}
                >
                  {link.icon}
                </a>
              </li>
            ))}
          </ul>
          <button
            type="button"
            className="menu__toggler"
            onClick={() => setActive(!active)}
          >
            <span />
            <span />
            <span />
          </button>
        </div>

        <div className="menu__content">
          <div className="content d-md-flex align-items-center">
            <ul className="content__section menu__items">
              {navigationLinks.map(link => (
                <li className="menu__item" key={link.label}>
                  <Link
                    to={link.url}
                    className="d-inline-block font-weight--700"
                    onClick={() => setActive(false)}
                  >
                    {link.label}
                  </Link>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </nav>
  )
}

export default Menu
