import React from "react"

// Libraries
import { Link } from "@reach/router"

// Icons
import IconLogo from "assets/icons/icon-logo-simplified.inline.svg"

const Footer = () => {
  // Navigation Links
  const navigationLinks = [
    {
      label: "Technology",
      url: "/technology",
      external: false,
    },
    {
      label: "Vision",
      url: "https://ndb.city",
      external: true,
    },
    {
      label: "Learn",
      url: "/learn",
      external: false,
    },
    {
      label: "Auction",
      url: "/auction",
      external: false,
    },
    {
      label: "Contact Us",
      url: "/#contactUs",
      external: false,
    },
  ]

  // Policty Links
  const policyLinks = [
    {
      label: "Terms",
      url: "/coming-soon",
    },
    {
      label: "Policy",
      url: "/coming-soon",
    },
  ]

  // Social Links
  const socialLinks = [
    {
      label: "Facebook",
      url: "https://www.facebook.com/ndbtechnology/",
    },
    {
      label: "Instagram",
      url: "https://www.instagram.com/ndbtechnology/",
    },
    {
      label: "Twitter",
      url: "https://twitter.com/ndbtechnology",
    },
  ]

  return (
    <footer className="footer">
      <div className="container">
        <div className="row">
          <div className="d-none d-sm-block col-sm-3 col-md-3">
            <ul>
              <Link className="green-first-letter" to="/">
                <li>
                  <span className="fs-25 font-weight-700">Home</span>
                </li>
              </Link>
              {navigationLinks.map(link => (
                <li key={link.label}>
                  {link.external ? (
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href={link.url}
                    >
                      {link.label}
                    </a>
                  ) : (
                    <Link to={link.url}>{link.label}</Link>
                  )}
                </li>
              ))}
            </ul>
          </div>
          <div className="d-none d-sm-block col-sm-3 col-md-3">
            <ul>
              <Link to="/">
                <li>
                  <span className="fs-25 font-weight-700">Follow</span>
                </li>
              </Link>
              {socialLinks.map(link => (
                <li key={link.label}>
                  <a
                    href={link.url}
                    className="fs-25 font-weight-400"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {link.label}
                  </a>
                </li>
              ))}
              {policyLinks.map(link => (
                <li key={link.label}>
                  <Link to={link.url}>{link.label}</Link>
                </li>
              ))}
            </ul>
          </div>
          <div className="col-12 col-sm-6 col-md-3">
            <div className="fs-25 font-weight-700 mt-1">Visit</div>
            <br />
            <div className="mt-12-px">
              <p className="font-weight-bold">Switzerland</p>
              <p className="font-weight-400">
                Microcity SA
                <br />
                Rue Pierre-à-Mazel 39
                <br />
                2000 Neuchâtel
              </p>
            </div>
            <br />
            <a
              href="https://ndb.technology/"
              className="d-inline-block mb-4 font-weight--700 mt-16-px"
              target="_blank"
              rel="noopener noreferrer"
            >
              ndb.technology
            </a>

            <ul className="d-sm-none">
              <li className="mb-4">
                <Link activeClassName="active" to="/terms">
                  Terms
                </Link>
              </li>
              <li className="mb-4">
                <Link to="/policy">Policy</Link>
              </li>
            </ul>

            <Link
              to="/"
              className="footer__logo d-flex flex-column align-items-center align-items-sm-start mt-10-px"
            >
              <IconLogo />
              <p className="pt-1">ENERGY TECHNOLOGY-REINVENTED</p>
            </Link>

            <p className="d-md-none text-center mt-4">
              © 2021 Voltamond SA. All rights reserved.
            </p>
          </div>
        </div>
      </div>
    </footer>
  )
}
export default Footer
