import React, { useEffect } from "react"

// Libraries
import PropTypes from "prop-types"

// Styles
import "assets/styles/reset.css"
import "assets/styles/main.scss"

// Components
import Seo from "components/seo/"
import Menu from "components/menu/"
import Footer from "components/footer/"
import FixedFooter from "components/fixed-footer/"

const Layout = ({ disableScrollingEffects, footerLess, children, ...rest }) => {
  // Transforms vertical scroll into horizontal scroll
  useEffect(() => {
    const handleScroll = event => {
      const main = document.getElementById("main")
      const { deltaY } = event
      const openDropdown = document.querySelector(".dropdown.dropdown--active")

      if (openDropdown) {
        if (!openDropdown.contains(event.target)) {
          main.scrollLeft += window.scrollX + deltaY
        }
      } else {
        main.scrollLeft += window.scrollX + deltaY
      }
    }

    if (window.innerWidth >= 1024 && !disableScrollingEffects) {
      document.addEventListener("wheel", handleScroll)
      return () => document.removeEventListener("wheel", handleScroll)
    }

    return null
  }, [])

  // Handles anchor links with horizontal scroll
  useEffect(() => {
    const handleClick = event => {
      event.preventDefault()

      const id = event.target.getAttribute("href").split("#")[1]
      const target = document.getElementById(id)

      document.getElementById("main").scrollTo({
        left: target.offsetLeft,
        behavior: "smooth",
      })
    }

    if (window.outerWidth >= 1024) {
      const anchorLinks = document.querySelectorAll(".sidebar a")

      anchorLinks.forEach(link => {
        link.addEventListener("click", handleClick)
      })

      return () => {
        anchorLinks.forEach(link => {
          link.removeEventListener("click", handleClick)
        })
      }
    }

    return false
  }, [])

  return (
    <main id="main" {...rest}>
      <Seo />
      <Menu />
      {children}
      {!footerLess && <Footer />}
      <FixedFooter />
    </main>
  )
}

Layout.propTypes = {
  disableScrollingEffects: PropTypes.bool,
  footerLess: PropTypes.bool,
  children: PropTypes.element,
}

Layout.defaultProps = {
  disableScrollingEffects: false,
  children: null,
  footerLess: false,
}

export default Layout
